<template lang="pug">
	.dashboard
		.dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
			Sidebar-user
		.dashboard-content
			b-container
				#my-shop.mt-5
					h3.mb-4 Mi Tienda
					b-row
						b-col(cols="12")
							form(enctype="multipart/form-data" novalidate v-if="isInitial || isSaving")
								h2 Upload images
								.dropbox
									input(
										type="file"
										:name="uploadFieldName"
										:disabled="isSaving"
										@change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length" accept="image/*"
									).input-file
									div(v-if="isInitial").initial
										p Arrastre su imagen aquí para comenzar
										p o haga clic para navegar
										.icon
											font-awesome-icon(icon="plus")
									p(v-if="isSaving") cargando {{ fileCount }} imagen...
							div(v-if="isSuccess")
								h2 {{ uploadedFile.length }} imagen cargada con éxito.
								p
									a(href="javascript:void(0)" @click="reset()") Upload again
								img(:src="uploadedFile.url" class="img-responsive img-thumbnail" :alt="uploadedFile.originalName")
							div(v-if="isFailed")
								h2 Uploaded failed.
								p
									a(href="javascript:void(0)" @click="reset()") Try again
								pre {{ uploadError }}
							#logo
								font-awesome-icon(icon="plus")
							form#description-company.mt-4.mb-5
								b-row
									b-col
										b-form-input(placeholder="Nombre Empresa")
									b-col
										b-form-input(placeholder="Télefono")
									b-col
										b-form-input(placeholder="Dirección")
									b-col
										b-form-input(placeholder="Página Web")
									b-col(cols="12").mt-3
										b-form-textarea(
											placeholder="Información Empresa"
											rows="5"
										)
								b-button(variant="outline-okcarpremium").px-5.mt-3
									
									|  Guardar

</template>
<script>
import SidebarUser from "@/components/dashboard/SidebarUser"
import { upload } from '@/file-upload.fake.service' // fake service
import { wait } from '@/utils';

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
	name:"MyShop",
	data(){
		return {
			banner: "",
			avatar: "",
			uploadedFile: {},
			uploadError: null,
			currentStatus: null,
			uploadFieldName: 'photos'
		}
	},
	components: {
		SidebarUser
	},
	computed: {
		isInitial() {
			return this.currentStatus === STATUS_INITIAL;
		},
		isSaving() {
			return this.currentStatus === STATUS_SAVING;
		},
		isSuccess() {
			return this.currentStatus === STATUS_SUCCESS;
			alert('Cargado')
		},
		isFailed() {
			return this.currentStatus === STATUS_FAILED;
		}
	},
	methods: {
		reset() {
			// reset form to initial state
			this.currentStatus = STATUS_INITIAL
			this.uploadedFile = {}
			this.uploadError = null
		},
		save(formData) {
			// upload data to the server
			this.currentStatus = STATUS_SAVING

			upload(formData)
				.then(x => {
					this.uploadedFile = x[0]
					this.currentStatus = STATUS_SUCCESS
				})
				.catch(err => {
					this.uploadError = err.response
					this.currentStatus = STATUS_FAILED
				});
		},
		filesChange(fieldName, fileList) {
			// handle file changes
			const formData = new FormData()

			if (!fileList.length) return

			// append the files to FormData
			Array
				.from(Array(fileList.length).keys())
				.map(x => {
					formData.append(fieldName, fileList[x], fileList[x].name)
				});

			// save it
			this.save(formData);
		},
		changeImageBanner(evt) {
			this.readURL(evt.target)
		},
		readURL(input) {
			if (input.files && input.files[0]) {
				const reader = new FileReader();
				
				reader.onload = function(e) {
					document.getElementById("banner").style.backgroundImage = `url('${e.target.result}'`
					this.banner = e.target.result
					console.log(e.target.result)
				}
				this.$emit('input', this.banner);
				reader.readAsDataURL(input.files[0]); // convert to base64 string
			}
		}
	},
	mounted() {
		this.reset();
	},
}
</script>
<style lang="scss">
	@import "@/assets/scss/_variables";

	.dropbox {
		align-items:center;
    border: 4px dashed #bbb;
		box-shadow: 0 0 0 .5rem white;;
		border-radius: .5rem;
		display: flex;
    background: white;
    color: #bbb;
		transition: ease all .3s;
    padding: 10px 10px;
    min-height: 250px; /* minimum height */
    position: relative;
    cursor: pointer;
		margin: 2rem .5rem 2rem .5rem;
		&:hover {
			background: lightblue; /* when mouse over to the drop zone, change color */
			text-decoration: none;
			border-color: $primary;
			color: $primary;
		}
		.fa-plus{
			padding: 10px;
			border: dashed;
			border-radius: 50%;
			width: 50px;
			height: 50px;
		}
		p {
			font-size: 1.2em;
			text-align: center;
			padding: 0;
			margin: 0;
		}
		.initial {
			text-align: center;
			margin: 0 auto;
			.icon {
				margin-top: 20px;
				width: 50px;
				height: 50px;
				display: inline-block;
			}
		}
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

	#banner {
		display: flex;
		background-color: white;
		border: dashed #bbb 4px;
		color: #bbb;
		font-size: 1.3rem;
		flex-direction:column;
		text-align: center;
		justify-content: center;
		margin-bottom: 10px;
		border-radius: 10px;
		transition: ease all .3s;
		background-size: cover;
		background-position: center center;
		height: 250px;
		
	}
	#logo {
		background-color: white;
		border: dashed #bbb 4px;
		color: #bbb;
		font-size: 1.3rem;
		padding: 40px 0;
		text-align: center;
		border-radius: 50%;
		margin-left: 20px;
		margin-top: -95px;
		transition: ease all .3s;
		width: 120px;
		height: 120px;
		position: relative;
		&:hover{
			text-decoration: none;
			border-color: $primary;
		}
		.fa-plus{
			margin: -14px 10px;
			padding: 10px;
			width: 45px;
			height: 45px;
		}
	}
	.image-upload {
		display: none;
	}
</style>